import { Supply } from '@sq/data/schemas'
import { getSupplyArea, getSupplyPedestrianFlux } from '@sq/data/utils'

export const formatMapMarkerPrice = (price: number) =>
    `R$${price.toLocaleString('pt-BR', { maximumFractionDigits: 0 })}`

export const toAddressLine1Visit = (supply: Supply) =>
    [supply.address.logradouro, `N. ${supply.address.number}`, supply.address.bairro].filter(Boolean).join(', ')

export const toAddressLine2Visit = (supply: Supply) =>
    [supply.address.complemento, `${getSupplyArea(supply)} m²`].filter(Boolean).join(' - ')

export const toAddressLine1Supply = (supply: Supply) =>
    [supply.address.logradouro, supply.address.number, supply.address.complemento].filter(Boolean).join(', ')

export const toAddressLine1Offer = (supply: Supply) =>
    supply.address.complemento
        ? `${supply.address.logradouro}, N. ${supply.address.number} - ${supply.address.complemento} - ${getSupplyArea(supply)} m²`
        : `${supply.address.logradouro}, N. ${supply.address.number} - ${getSupplyArea(supply)} m²`

export const toAddressLine2Offer = (supply: Supply) => `${supply.address.bairro}`
export const toAddressLine2Supply = (supply: Supply) => supply.address.bairro

export const toAddressStreetAndNumber = (supply: Supply) => `${supply.address.logradouro}, N. ${supply.address.number}`

export const toAddressComplementAndSize = (supply: Supply) =>
    supply.address.complemento
        ? `${supply.address.complemento} - ${getSupplyArea(supply)} m²`
        : ` ${getSupplyArea(supply)} m²`

export const toAddressHoodAndCity = (supply: Supply) => `${supply.address.bairro}, ${supply.address.localidade}`

export const isPedestrianFluxHigh = (supply: Supply) => getSupplyPedestrianFlux(supply) >= 4

export const getSupplyTypeDetail = (supply: Supply): string => supply.details['tipo-de-imovel']?.value ?? 'Loja'

export const getSuppliesAverageArea = (supplies: Supply[]): number => {
    const totalArea = supplies.reduce((acc, supply) => {
        const size = supply.details['tamanho-do-imovel']?.value
        if (!size) {
            return acc
        }
        const area = Number.parseInt(size) / 100
        return area ? acc + area : acc
    }, 0)

    return Math.round(totalArea / supplies.length)
}

export const getSuppliesMostCommonType = (supplies: Supply[]): string => {
    if (supplies.length === 0) {
        return ''
    }
    const types = supplies.map((supply) => supply.details['tipo-de-imovel']?.value).filter(Boolean) as string[]
    const counts = types.reduce(
        (acc, type) => {
            acc[type] = (acc[type] || 0) + 1
            return acc
        },
        {} as Record<string, number>
    )

    return Object.entries(counts).sort((a, b) => b[1] - a[1])[0][0]
}

export const getSuppliesTopXNeighborhoods = (supplies: Supply[], num: number): string[] => {
    if (supplies.length === 0) {
        return []
    }

    const neighborhoods = supplies.map((supply) => supply.address.bairro)
    const counts = neighborhoods.reduce(
        (acc, neighborhood) => {
            acc[neighborhood] = (acc[neighborhood] || 0) + 1
            return acc
        },
        {} as Record<string, number>
    )

    return Object.entries(counts)
        .sort((a, b) => b[1] - a[1])
        .slice(0, num)
        .map(([neighborhood]) => neighborhood)
}

export const getSupplyLastUse = (supply: Supply): string | undefined => {
    const lastUse = supply.details['antes-era']?.value
    return lastUse ?? undefined
}

export const sortByPriceAscending = (a: Supply, b: Supply) => {
    const priceA = a.details['preço'] ? Number.parseInt(a.details['preço'].value) : 0
    const priceB = b.details['preço'] ? Number.parseInt(b.details['preço'].value) : 0
    return priceA - priceB
}
export const sortByPriceDescending = (a: Supply, b: Supply) => {
    const priceA = a.details['preço'] ? Number.parseInt(a.details['preço'].value) : 0
    const priceB = b.details['preço'] ? Number.parseInt(b.details['preço'].value) : 0
    return priceB - priceA
}
export const sortByMostSeen = (a: Supply, b: Supply) => {
    const pageViewsA = a.details['pageviews'] ? Number.parseInt(a.details['pageviews'].value) : 0
    const pageViewsB = b.details['pageviews'] ? Number.parseInt(b.details['pageviews'].value) : 0
    return pageViewsB - pageViewsA
}
export const sortByMostRecent = (a: Supply, b: Supply) => {
    return new Date(b.published_at).getTime() - new Date(a.published_at).getTime()
}
export const sortByPedestrianTraffic = (a: Supply, b: Supply) => {
    const pedestrianTrafficA = a.details['fluxo-pedestre'] ? Number.parseInt(a.details['fluxo-pedestre'].value) : 0
    const pedestrianTrafficB = b.details['fluxo-pedestre'] ? Number.parseInt(b.details['fluxo-pedestre'].value) : 0
    return pedestrianTrafficB - pedestrianTrafficA
}

export const supplyAreaIsLowerOrEqualTo = (supply: Supply, area: number) => {
    const size = supply.details['tamanho-do-imovel']?.value
    if (!size) {
        return false
    }
    return Number.parseInt(size) / 100 <= area
}

export const supplyAreaIsHigherOrEqualTo = (supply: Supply, area: number) => {
    const size = supply.details['tamanho-do-imovel']?.value
    if (!size) {
        return false
    }

    return Number.parseInt(size) / 100 >= area
}

export const supplyIsOfType = (supply: Supply, type: string) => supply.details['tipo-de-imovel']?.value === type

export const groupSupplyByIds = (supplies: Supply[]) =>
    supplies.reduce(
        (acc: Record<Supply['id'], Supply>, supply) => ({
            ...acc,
            [supply.id]: supply,
        }),
        {}
    )
