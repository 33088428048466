import { Supply } from '@sq/data/schemas'

export const getStringDetailValue = (key: string) => (supply: Supply) => {
  const detail = supply.details[key]
  if (!detail) return ''

  if (detail.type !== 'string')
    throw new Error(`Expected ${key} detail to be of type string, got ${detail.type}`)

  return detail.value
}

export const getIntDetailValue = (key: string) => (supply: Supply) => {
  const detail = supply.details[key]
  if (!detail) return 0

  if (detail.type !== 'int' && detail.type !== 'circle')
    throw new Error(`Expected ${key} detail to be of type int or circle, got ${detail.type}`)

  return parseInt(detail.value)
}

export const getDecimalDetailValue = (key: string) => (supply: Supply) => {
  const detail = supply.details[key]
  if (!detail) return 0

  if (detail.type !== 'decimal')
    throw new Error(`Expected ${key} detail to be of type decimal, got ${detail.type}`)

  return parseInt(detail.value) / 100
}

export const getSupplyPedestrianFlux = getIntDetailValue('fluxo-pedestre')

export const getSupplyArea = getDecimalDetailValue('tamanho-do-imovel')

export const getSupplyPrice = getDecimalDetailValue('preço')

export const getSupplyIptu = getDecimalDetailValue('iptu')

export const getSupplyCondominio = getDecimalDetailValue('condominio')

export const getSupplyPageViews = getIntDetailValue('page-views')

export const getSupplyLeaseFee = getDecimalDetailValue('luva')

export const getSupplyTotalPrice = (supply: Supply) =>
  getSupplyIptu(supply) + getSupplyPrice(supply) + getSupplyCondominio(supply)

export const getSupplyType = getStringDetailValue('tipo-de-imovel')
