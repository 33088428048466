import { useCallback } from 'react'

import SQSlider from '@/components/_shared/SQSlider/SQSlider'
import { Range } from '@/lib/@core/search/search.model'
import { maxPrice } from '@/lib/@core/search/search.state'
import { rangeToSliderValue } from '@/lib/@core/search/search.utils'

import PriceInput from './PriceInput'

type Props = {
    value: Range
    min?: number
    max?: number
    step?: number | undefined
    minDistance?: number
    currencySymbol?: string
    locale?: string
    onChangeMin: (value: number) => void
    onChangeMax: (value: number) => void
}

const clamp = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max)

export default function PriceFilter({
    value,
    min = 0,
    max = maxPrice,
    step = 1000,
    minDistance = 1000,
    currencySymbol = 'R$',
    locale = 'pt-BR',
    onChangeMin,
    onChangeMax,
}: Props) {
    const onChangeSlider = useCallback(
        (values: readonly number[]) => {
            onChangeMin(values[0])
            onChangeMax(values[1])
        },
        [onChangeMin, onChangeMax]
    )

    return (
        <>
            <p className="text-left text-xs">Preço sem IPTU e condomínio</p>
            <div className="my-4 flex w-full gap-2 text-left">
                <div>
                    <label htmlFor="price-min">Mínimo</label>

                    <PriceInput
                        id="price-min"
                        value={value.min}
                        currencySymbol={currencySymbol}
                        locale={locale}
                        onChange={(newMin) => onChangeMin(clamp(newMin, min, max))}
                    />
                </div>
                <div>
                    <label htmlFor="price-max">Máximo</label>
                    <PriceInput
                        id="price-max"
                        value={value.type === 'open' ? max : value.max}
                        maxValue={max}
                        currencySymbol={currencySymbol}
                        locale={locale}
                        onChange={(newMax) => onChangeMax(clamp(newMax, min, max))}
                    />
                </div>
            </div>
            <SQSlider
                value={rangeToSliderValue(value, max)}
                min={min}
                max={max}
                step={step}
                minDistance={minDistance}
                onChange={onChangeSlider}
            />
        </>
    )
}
